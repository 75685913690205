<script setup lang="ts">
  import { ref, inject } from 'vue'
  import NavbarMenu from '@/components/NavbarMenu.vue'
  import AddColumn from './AddColumn.vue'

  const appName = inject('appName') as string
  const showMobileMenu = ref(false)
</script>

<template>
  <nav
    class="navbar bg-base-200 min-h-auto flex-wrap uppercase shadow-sm select-none print:hidden"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="min-w-[42px]">
      <router-link
        key="home"
        :to="{ name: 'deck' }"
        name="home"
        aria-label="home"
        class="btn btn-ghost pl-0 hover:border-transparent hover:bg-transparent"
      >
        <img
          :alt="appName"
          src="@/assets/img/afpdeck_logo.png"
          width="42"
          height="42"
        />
        <span class="sr-only">Deck</span>
      </router-link>
    </div>
    <AddColumn
      class="flex flex-1 gap-2"
      key="add-column"
    />
    <button
      class="btn btn-ghost p-0 lg:hidden"
      @click.prevent="showMobileMenu = !showMobileMenu"
    >
      <label class="btn btn-ghost swap swap-rotate">
        <!-- hamburger icon -->
        <svg
          class="swap-off fill-current"
          :class="{
            'swap-off': !showMobileMenu,
            'swap-on': showMobileMenu
          }"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 512 512"
        >
          <path
            d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z"
          />
        </svg>

        <!-- close icon -->
        <svg
          class="fill-current"
          :class="{
            'swap-off': showMobileMenu,
            'swap-on': !showMobileMenu
          }"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 512 512"
        >
          <polygon
            points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49"
          />
        </svg>
      </label>
    </button>
    <NavbarMenu
      class="menu menu-horizontal ml-auto w-full flex-wrap justify-end p-0 lg:inline-flex lg:w-auto"
      :class="{
        hidden: !showMobileMenu
      }"
    />
  </nav>
</template>
