import TextDocument from './TextDocument'
import MultimediaDocument from './MultimediaDocument'
import WebStoryDocument from './WebStoryDocument'
import PictureDocument from './PictureDocument'
import VideoDocument from './VideoDocument'
import { z } from 'zod'

const SourceSchema = z.object({
  class: z.enum([
    'text',
    'factcheck',
    'multimedia',
    'picture',
    'graphic',
    'video',
    'videography',
    'webstory'
  ])
})

export default function parseDocument(docSource: unknown) {
  try {
    const doc = SourceSchema.parse(docSource)
    switch (doc.class) {
      case 'text':
        return new TextDocument(docSource, 'text')
      case 'factcheck':
        return new TextDocument(docSource, 'factcheck')
      case 'multimedia':
        return new MultimediaDocument(docSource)
      case 'picture':
        return new PictureDocument(docSource, 'photo')
      case 'graphic':
        return new PictureDocument(docSource, 'graphic')
      case 'video':
      case 'videography':
        return new VideoDocument(docSource)
      case 'webstory':
        return new WebStoryDocument(docSource)
      default:
        throw new Error('Invalid class')
    }
  } catch (e) {
    console.debug(`Erreur lors de la conversion du document`, docSource)
    console.warn(e)
  }
}

export type Document = NonNullable<ReturnType<typeof parseDocument>>
