import { ZodSchema, z } from 'zod'

const makeFilteredArraySchema = <T extends ZodSchema>(schema: T) =>
  z
    .array(z.unknown())
    .transform(items =>
      items?.filter(
        (item): item is z.infer<T> => schema.safeParse(item).success
      )
    )
    .refine(items => items.length > 0)

export const PictureSizeSchema = z.object({
  role: z.enum(['HighDef', 'Preview', 'Thumbnail']),
  width: z.number(),
  height: z.number(),
  href: z.string().url(),
  type: z.enum(['Photo'])
})

export const VideoSizeSchema = z.object({
  role: z.string(),
  width: z.number(),
  height: z.number(),
  href: z.string().url(),
  type: z.enum(['Video'])
})

const MediaSchema = z.object({
  uno: z.string(),
  creator: z.string().optional(),
  provider: z
    .object({
      name: z.string()
    })
    .optional(),
  caption: z.string().optional(),
  newslines: z.object({
    dateline: z.string()
  })
})

export const PictureSchema = z.intersection(
  MediaSchema,
  z.object({
    medias: makeFilteredArraySchema(PictureSizeSchema)
  })
)

export const VideoSchema = z.intersection(
  MediaSchema,
  z.object({
    medias: makeFilteredArraySchema(
      z.union([PictureSizeSchema, VideoSizeSchema])
    )
  })
)
