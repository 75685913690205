import { onBeforeMount, ref } from 'vue'

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[]
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed'
    platform: string
  }>
  prompt(): Promise<void>
}

export default function useInstallApp() {
  const promptStorage = ref()
  const outcomeStorage = ref()

  onBeforeMount(() => {
    // Setup a listener to track Add to Homescreen events.
    window.addEventListener('beforeinstallprompt', async evt => {
      const { prompt, userChoice } = evt as BeforeInstallPromptEvent
      promptStorage.value = prompt
      const outcome = await userChoice
      outcomeStorage.value = outcome
    })
  })

  return {
    promptStorage,
    outcomeStorage
  }
}
