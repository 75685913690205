import type { Plugin } from 'vue'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import 'dayjs/locale/fr'
import 'dayjs/locale/en'
import 'dayjs/locale/es'
import 'dayjs/locale/pt'
import 'dayjs/locale/de'
import type { Locale } from '@/types'

dayjs.extend(relativeTime)
dayjs.extend(calendar)

declare module 'vue' {
  interface ComponentCustomProperties {
    $dayjs: {
      calendar(value: Date, now: Date, format: any): string
      calendarRelative(
        value: Date,
        reference: Date,
        now: Date,
        format: any
      ): string
      fromNow(value: Date, now: Date): string
    }
  }
}

const VueDayJS: Plugin = {
  install(app) {
    app.config.globalProperties.$dayjs = {
      calendar(value, now, format) {
        return dayjs(value).calendar(dayjs(now), format)
      },
      calendarRelative(value, reference, now, format) {
        if (dayjs(value).isSame(dayjs(reference), 'day')) {
          return dayjs(value).format(format.onlyHour)
        }
        return dayjs(value).calendar(dayjs(now), format)
      },
      fromNow(value, now) {
        return dayjs(value).from(dayjs(now))
      }
    }
  }
}

export function changeDayJsLocale(locale: Locale): void {
  dayjs.locale(locale.substring(0, 2))
}

export default VueDayJS
