<script setup lang="ts">
  import topicsConfig from '@/config/topics.json'
  import { computed, toRefs } from 'vue'
  import { type Topics } from '@/types'

  const props = defineProps({
    slugs: {
      type: Array<string>,
      default: () => []
    },
    lang: {
      type: String,
      default: 'en'
    }
  })
  const { slugs, lang } = toRefs(props)

  const uniqueSlugs = computed(() => {
    const unique = [...new Set(slugs.value)]
    return unique.map(d => ({
      id: d,
      label: d
    }))
  })
</script>

<template>
  <nav
    v-if="uniqueSlugs.length > 0"
    class="flex flex-wrap gap-1 select-none"
  >
    <RouterLink
      v-for="slug in uniqueSlugs"
      :key="slug.id"
      :to="`/slug/${lang}/${slug.id}`"
      rel="tag"
      class="badge-secondary badge badge-sm has-text-weight-semibold rounded-xs"
    >
      {{ slug.label }}
    </RouterLink>
  </nav>
</template>
