<script setup lang="ts">
  import MultimediaDocument from '@/store/parser/MultimediaDocument'
  import { toRefs, computed, ref, type Ref, type PropType } from 'vue'
  import { useElementSize, onKeyStroke } from '@vueuse/core'
  import { useCurrentElement } from '@vueuse/core'

  const props = defineProps({
    medias: {
      type: Object as PropType<MultimediaDocument['medias']>,
      required: true
    }
  })

  const { medias } = toRefs(props)

  const current = ref(0)
  const el = useCurrentElement() as Ref<HTMLElement>
  const { width: currentWidth } = useElementSize(el)

  const mediasRatios = computed(() =>
    medias.value.map(media => {
      const size = media.sizes.filter(
        d => d.role !== 'Thumbnail' && d.width > 320
      )[0]
      return { ratio: size.height / size.width, ...media }
    })
  )

  const media = computed(() => mediasRatios.value[current.value])

  onKeyStroke('ArrowRight', e => {
    next()
    e.preventDefault()
  })
  onKeyStroke('ArrowLeft', e => {
    previous()
    e.preventDefault()
  })

  function previous() {
    current.value =
      current.value - 1 < 0 ? medias.value.length - 1 : current.value - 1
  }

  function next() {
    current.value =
      current.value + 1 > medias.value.length - 1 ? 0 : current.value + 1
  }

  function goTo(i: number) {
    current.value = i
  }

  function sourceSet(media: MultimediaDocument['medias'][number]) {
    const preview = media.sizes.find(d => d.role === 'Preview')
    const hires = media.sizes.find(d => d.role === 'HighDef')
    return (
      preview?.href +
      ' ' +
      preview?.width +
      'w, ' +
      hires?.href +
      ' ' +
      hires?.width +
      'w'
    )
  }
</script>

<template>
  <div class="print:float-right print:m-4 print:max-w-1/2">
    <transition
      name="fade"
      mode="out-in"
    >
      <figure
        :key="media.id"
        class="relative -mr-[var(--document-padding-left)] mb-2 -ml-[var(--document-padding-left)] sm:-mr-[var(--document-padding-right)] print:m-auto"
      >
        <div :style="{ paddingTop: `${media.ratio * 100}%` }" />
        <video
          v-if="media.sizes.some(size => size.type === 'Video')"
          :poster="media.sizes.find(d => d.role === 'HighDef')?.href"
          controls
          controlsList="nodownload"
          autoplay
          muted
          class="absolute top-1/2 left-0 w-full transform-[translateY(-50%)]"
        >
          <source
            v-for="source in media.sizes
              .filter(
                size =>
                  size.type === 'Video' &&
                  size.role[size.role.length - 1] !== 'W' &&
                  size.width <= 720
              )
              .sort((a, b) => b.width - a.width)"
            :key="source.href"
            :src="source.href"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <img
          v-else
          :src="media.sizes.find(d => d.role === 'Preview')?.href"
          :srcset="sourceSet(media)"
          :sizes="`${currentWidth}px`"
          class="absolute top-1/2 left-0 w-full transform-[translateY(-50%)]"
        />
      </figure>
    </transition>
    <p
      v-if="media.caption"
      class="p-5 text-sm"
    >
      {{ media.caption }}. {{ media.creator }} /
      {{ media.provider }}
    </p>
    <ul
      v-if="mediasRatios.length > 1"
      class="join flex flex-wrap justify-center gap-1 print:hidden"
    >
      <li
        v-for="(m, i) in mediasRatios"
        :key="m.id"
        :class="{ 'btn-neutral': i === current }"
        class="join-item btn btn-square border-2"
        @click="goTo(i)"
      >
        <img
          :src="m.sizes.find(d => d.role === 'Thumbnail')?.href"
          class="aspect-square object-cover"
        />
      </li>
    </ul>
  </div>
</template>
