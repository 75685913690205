import { createRouter, createWebHistory } from 'vue-router'
import DeckView from '@/views/DeckView.vue'
import LoginView from '@/views/LoginView.vue'
import ViewerComponent from '@/views/Viewer/ViewerComponent.vue'
import AboutView from '@/views/AboutView.vue'
import DebugView from '@/views/DebugView.vue'
import { useDeckStore } from '@/store'
import { preferredLocale } from '@/plugins/i18n'

import useHistoryState from './middlewares/useHistoryState'
import useCheckCredentials from './middlewares/useCheckCredentials'
import useDb from './middlewares/useDb'

function wrapDoubleQuotes(str: string) {
  if (str.includes(' ')) {
    return `"${str}"`
  }
  return str
}

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: 'login',
      path: '/login',
      component: LoginView
    },
    {
      name: 'about',
      path: '/about',
      component: AboutView,
      meta: {
        authProtected: false
      }
    },
    {
      name: 'debug',
      path: '/debug',
      component: DebugView,
      meta: {
        authProtected: true
      }
    },
    {
      name: 'deck',
      path: '/',
      component: DeckView,
      meta: {
        authProtected: true
      },
      children: [
        {
          name: 'document',
          path: 'doc/:docId',
          component: ViewerComponent,
          props: route => {
            return {
              docId: Array.isArray(route.params.docId)
                ? route.params.docId[0]
                : route.params.docId,
              columnId: route.meta.columnId,
              transition: route.meta.transition
            }
          },
          meta: {
            authProtected: true,
            transition: 'slide-left'
          }
        }
      ]
    },
    {
      name: 'slug',
      path: '/slug/:lang/:slugs',
      redirect: to => {
        const deckStore = useDeckStore()
        const { addColumn } = deckStore
        const slugs = Array.isArray(to.params.slugs)
          ? to.params.slugs
          : to.params.slugs.split(',')
        addColumn({
          type: 'search',
          params: {
            langs: [preferredLocale.value],
            query: slugs.map(d => `slug:${wrapDoubleQuotes(d)}`).join(' AND ')
          }
        })
        return { name: 'deck', params: {} }
      }
    },
    {
      name: 'topic',
      path: '/topic/:lang/:topic?',
      redirect: to => {
        const deckStore = useDeckStore()
        const { addColumn } = deckStore
        const lang = Array.isArray(to.params.lang)
          ? to.params.lang[0]
          : to.params.lang
        const topic = Array.isArray(to.params.topic)
          ? to.params.topic[0]
          : to.params.topic
        addColumn({
          type: 'topic',
          params: {
            langs: [lang],
            topic
          }
        })
        return { name: 'deck', params: {} }
      }
    },
    {
      name: 'place',
      path: '/place/:country/:city?',
      redirect: to => {
        const deckStore = useDeckStore()
        const { addColumn } = deckStore
        const country = Array.isArray(to.params.country)
          ? to.params.country[0]
          : to.params.country
        const city = Array.isArray(to.params.city)
          ? to.params.city[0]
          : to.params.city
        addColumn({
          type: 'search',
          params: {
            langs: [preferredLocale.value],
            query: to.params.city
              ? `country:${wrapDoubleQuotes(
                  country
                )} AND city:${wrapDoubleQuotes(city)}`
              : `country:${wrapDoubleQuotes(country)}`
          }
        })
        return { name: 'deck', params: {} }
      }
    },
    {
      name: 'genre',
      path: '/genre/:genre',
      redirect: to => {
        const deckStore = useDeckStore()
        const { addColumn } = deckStore
        const genres = Array.isArray(to.params.genre)
          ? to.params.genre
          : [to.params.genre]
        addColumn({
          type: 'search',
          params: {
            langs: [preferredLocale.value],
            query: genres
              .map(genre => `genre:${wrapDoubleQuotes(genre)}`)
              .join(' AND ')
          }
        })
        return { name: 'deck', params: {} }
      }
    },
    {
      name: 'event',
      path: '/event/:event',
      redirect: to => {
        const deckStore = useDeckStore()
        const { addColumn } = deckStore
        const events = Array.isArray(to.params.event)
          ? to.params.event
          : [to.params.event]
        addColumn({
          type: 'search',
          params: {
            langs: [preferredLocale.value],
            query: events
              .map(event => `event:"afpevent:${event}"`)
              .join(' AND ')
          }
        })
        return { name: 'deck', params: {} }
      }
    },
    {
      name: 'creator',
      path: '/creator/:creator',
      redirect: to => {
        const deckStore = useDeckStore()
        const { addColumn } = deckStore
        const creators = Array.isArray(to.params.creator)
          ? to.params.creator
          : [to.params.creator]
        addColumn({
          type: 'search',
          params: {
            langs: [preferredLocale.value],
            query: creators
              .map(creator => `creator:${wrapDoubleQuotes(creator)}`)
              .join(' AND ')
          }
        })
        return { name: 'deck', params: {} }
      }
    }
  ]
})

useDb(router)
useCheckCredentials(router)
useHistoryState(router)

export default router
