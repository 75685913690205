<script setup lang="ts">
  import { useThrottleFn } from '@vueuse/core'
  import { vIntersectionObserver } from '@vueuse/components'
  import { computed, toRefs } from 'vue'
  import { useAutoRefresh } from '@/composables/useAutoRefresh'

  const emit = defineEmits<{
    (e: 'loadTop'): void
    (e: 'loadBottom'): void
  }>()

  const props = defineProps({
    list: {
      type: Array,
      required: true
    },
    size: {
      type: Number,
      default: 20 // The number of items added each time.
    },
    offset: {
      type: Number,
      default: 1000 // The number of pixels of additional length to allow scrolling to.
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    noMore: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  })
  const { list, size, offset, isLoading, noMore, error } = toRefs(props)

  const items = computed(() => list.value.map(renderItem))

  function renderItem(data: any) {
    return {
      data
    }
  }

  const { resume, pause } = useAutoRefresh(() => {
    if (isLoading.value) return
    emit('loadTop')
  })

  const onColumnIntersect = useThrottleFn(
    ([{ isIntersecting }]: IntersectionObserverEntry[]) => {
      if (isIntersecting && !error.value) {
        resume()
      } else {
        pause()
      }
    },
    1000,
    false,
    true
  )

  const onLoadingIntersect = ([
    { isIntersecting }
  ]: IntersectionObserverEntry[]) => {
    if (isIntersecting) {
      emit('loadBottom')
    }
  }
</script>

<template>
  <nav
    class="vue-recyclist scrollbar-thumb-base-300 scrollbar-thumb-rounded-full scrollbar-w-1 scrollbar-thin overflow-x-hidden overflow-y-scroll overscroll-y-contain"
    v-intersection-observer="[onColumnIntersect, { threshold: [0.5] }]"
  >
    <div
      v-if="!isLoading && error"
      class="text-error m-4 text-center"
    >
      <slot name="error">
        <div>Error</div>
      </slot>
    </div>
    <div
      v-for="(item, index) in items"
      :key="`doc-${index}`"
      class="w-full"
    >
      <slot
        :data="item.data"
        name="item"
      />
    </div>
    <div
      v-for="(_, index) in size"
      v-show="isLoading"
      :key="`tombstone-${index}`"
      class="w-full"
    >
      <slot name="tombstone" />
    </div>
    <div
      v-show="!noMore && !error"
      v-intersection-observer="[
        onLoadingIntersect,
        { rootMargin: `${offset}px` }
      ]"
    >
      <slot name="loading">
        <div>Loading</div>
      </slot>
    </div>
    <div
      v-if="!isLoading && noMore"
      class="m-4 text-center"
    >
      <slot name="nomore">
        <div>End of list</div>
      </slot>
    </div>
    <div
      v-if="!isLoading && error"
      class="text-error m-4 text-center"
    >
      <slot name="error"> Error </slot>
    </div>
  </nav>
</template>

<style scoped>
  .vue-recyclist {
    -webkit-overflow-scrolling: touch;
    contain: strict;
  }
  .vue-recyclist-item {
    content-visibility: auto;
    contain-intrinsic-size: 290px;
  }
</style>
