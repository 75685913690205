<script setup lang="ts">
  import ProgressiveImage from '@/components/ProgressiveImage.vue'
  import RelatedDocuments from '@/components/RelatedDocuments.vue'
  import SlugsComponent from '@/components/SlugsComponent.vue'
  import DocumentDetails from '@/components/DocumentDetails.vue'
  import { type PropType, computed, toRefs } from 'vue'
  import type PictureDocument from '@/store/parser/PictureDocument'
  import DockComponent from '@/components/DockComponent.vue'
  import { useI18n } from 'vue-i18n'
  import WebShare from '@/components/WebShare.vue'
  import CopyButton from '@/components/CopyButton.vue'
  import { useRoute, useRouter } from 'vue-router'

  const i18n = useI18n({ useScope: 'global' })
  const { t } = i18n

  const route = useRoute()
  const router = useRouter()

  const props = defineProps({
    doc: {
      type: Object as PropType<PictureDocument>,
      required: true
    }
  })

  import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
  const { smaller } = useBreakpoints(breakpointsTailwind)
  const isSmallerMd = smaller('sm')

  const emit = defineEmits<{
    (e: 'close'): void
  }>()

  const { doc } = toRefs(props)

  const legend = computed({
    get() {
      return route.query.legend ? true : false
    },
    set(legend) {
      router.replace({ query: { legend: legend ? 'true' : undefined } })
    }
  })

  const media = computed(() => doc.value.media)

  const textToCopy = computed(
    () => `${doc.value.slugs?.join(' | ')}
${doc.value.published.toLocaleDateString()} - ${doc.value.published.toLocaleTimeString()}
${doc.value.source || ''}${doc.value.source && doc.value.city ? ' • ' : ''}${doc.value.city || ''}${doc.value.city && doc.value.creator ? ' • ' : ''}${doc.value.creator || ''}

${doc.value.media.caption}

${doc.value.shortId || doc.value.id}`
  )
</script>

<template>
  <article class="bg-neutral print:bg-none">
    <ProgressiveImage
      :display-small="legend"
      :sizes="media.pictureSizes"
      :class="{ small: legend }"
      @toggle-details="legend = !legend"
    />
    <DockComponent
      class="bg-neutral text-base-100 dark:text-neutral-content w-full print:hidden"
      @close="emit('close')"
    >
      <template
        v-if="isSmallerMd"
        #left
        ><CopyButton
          :text="textToCopy"
          :title="t('document.copy')"
      /></template>
      <template
        v-if="isSmallerMd"
        #center
        ><WebShare
          :headline="doc.headline"
          :title="t('document.share')"
      /></template>
    </DockComponent>
    <transition
      name="slide"
      mode="in-out"
    >
      <aside
        v-show="legend"
        class="bg-base-100 print:bg-non absolute right-0 bottom-0 max-h-2/3 max-w-[var(--document-max-width)] overflow-y-auto overscroll-y-contain p-5 print:relative print:max-w-none print:overflow-auto"
      >
        <DocumentDetails :doc="doc" />
        <p class="pt-4 pb-5 text-lg">
          {{ doc.media.caption?.replace(doc.advisory || '', '') }}
        </p>
        <SlugsComponent
          v-if="doc.slugs"
          :slugs="doc.slugs"
          :lang="doc.lang"
          type="slug"
          class="mb-3"
          layout="horizontal"
        />

        <div class="join hidden md:block">
          <CopyButton
            :title="t('document.copy')"
            :text="textToCopy"
            class="btn btn-primary"
          >
            {{ t('document.copy') }}
          </CopyButton>
          <WebShare
            :headline="doc.headline"
            :text="textToCopy"
            class="btn btn-primary"
          >
            {{ t('document.share') }}
          </WebShare>
        </div>

        <div
          v-if="doc.advisory"
          role="alert"
          class="alert alert-soft mt-3"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 shrink-0 stroke-current"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
          <span>{{ doc.advisory }}</span>
        </div>

        <p
          v-if="route.params.docId"
          class="hidden print:block"
        >
          ID : {{ route.params.docId }}
        </p>

        <RelatedDocuments :doc="doc" />
      </aside>
    </transition>
  </article>
</template>

<style lang="css" scoped>
  .slide-enter-active,
  .slide-leave-active {
    transition: transform 0.3s ease-in-out;
  }
  .slide-enter-from,
  .slide-leave-to {
    transform: translateX(100%);
  }
</style>
