import GenericDocument from './GenericDocument'
import { z } from 'zod'
import { PictureSchema } from './MediasShemas'

type PictureType = 'photo' | 'graphic'

const SourceSchema = z.object({
  urgency: z.number(),
  bagItem: PictureSchema.array().length(1)
})

function extractMedia(media: z.infer<typeof PictureSchema>) {
  return {
    id: media.uno,
    creator: media.creator,
    provider: media.provider?.name,
    caption: media.caption,
    dateline: media.newslines.dateline,
    pictureSizes: media.medias
      .filter(d => d.type === 'Photo')
      .sort((a, b) => a.width - b.width)
  }
}

export default class PictureDocument extends GenericDocument {
  public type: PictureType
  public media
  public topshot

  constructor(docSource: unknown, type: PictureType) {
    super(docSource)
    this.type = type
    const doc = SourceSchema.parse(docSource)
    this.media = extractMedia(doc.bagItem[0])
    this.topshot = doc.urgency === 1
  }
}
