import { defineStore } from 'pinia'
import * as state from '@/store/state'
import * as getters from '@/store/getters'
import * as mutations from '@/store/mutations'
import * as actions from '@/store/actions'

export const useDeckStore = defineStore('deck', () => {
  return {
    ...state,
    ...getters,
    ...mutations,
    ...actions
  }
})
