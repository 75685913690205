<script setup lang="ts">
  import { useI18n } from 'vue-i18n'
  import { computed, toRefs } from 'vue'
  import { useDeckStore } from '@/store'
  import { useNow } from '@vueuse/core'
  import { Urgency } from '@/store/parser/TextDocument'

  const { locale } = useI18n()

  const props = defineProps({
    docId: {
      type: String,
      required: true
    },
    columnId: {
      type: String,
      required: false,
      default: null
    }
  })

  const { docId, columnId } = toRefs(props)
  const deckStore = useDeckStore()
  const { getDocumentById } = deckStore

  const doc = computed(() => getDocumentById(docId.value))
  const now = useNow({ interval: 10000 })

  const PreviewMedia = computed(() => {
    if (!doc.value) return
    if (
      doc.value.type === 'photo' ||
      doc.value.type === 'graphic' ||
      doc.value.type === 'video'
    ) {
      return doc.value.media.pictureSizes.find(size =>
        ['Preview', 'Thumbnail', 'HighDef'].includes(size.role)
      )
    }
    if (doc.value.type === 'multimedia' || doc.value.type === 'webstory') {
      if (doc.value.medias.length === 0) return false
      return doc.value.medias[0].sizes.find(size => size.role === 'Preview')
    }
    return false
  })
</script>

<template>
  <RouterLink
    :class="{
      'border-accent border-l-12':
        doc.type === 'text' && doc.urgency === Urgency.Flash,
      'border-warning border-l-12':
        doc.type === 'text' && doc.urgency === Urgency.Alert,
      'border-l-12 border-yellow-200':
        doc.type === 'text' && doc.urgency === Urgency.Urgent,
      'border-l-12 border-yellow-100':
        (doc.type === 'text' || doc.type === 'multimedia') &&
        doc.hasBeenAlerted,
      'pt-2': !PreviewMedia
    }"
    :lang="doc.lang"
    :dir="doc.lang === 'ar' ? 'rtl' : 'ltr'"
    :to="{
      name: 'document',
      params: { docId },
      state: { columnId, docType: doc.type }
    }"
    class="group card bg-base-100 mb-3 pb-2 shadow transition-shadow hover:shadow-md"
  >
    <figure
      v-if="PreviewMedia"
      :style="{ backgroundImage: `url(${PreviewMedia.href})` }"
      class="mb-2 aspect-video bg-cover bg-center"
    ></figure>
    <div class="card-body px-4 py-3">
      <div class="flex justify-between px-2 text-xs text-slate-400">
        <p
          v-if="doc.embargoed && doc.embargoed > new Date()"
          :key="`date-${locale}-embargo`"
        >
          Embargo : {{ $dayjs.fromNow(doc.embargoed, now) }}
        </p>
        <p
          v-else
          :key="`date-${locale}`"
        >
          {{ $dayjs.fromNow(doc.published, now) }}
        </p>
        <p v-if="doc.type === 'photo' && doc.topshot">Topshot</p>
        <p
          v-else-if="
            (doc.type === 'text' ||
              doc.type === 'multimedia' ||
              doc.type === 'factcheck') &&
            doc.genre
          "
          class="text-right font-semibold uppercase"
        >
          <span>
            {{ doc.genre }}
          </span>
        </p>
      </div>
      <h2
        v-if="doc.type !== 'photo'"
        class="card-title mt-1 text-2xl leading-6 tracking-tight group-visited:text-gray-400"
        :class="{
          'line-through': doc.status === 'Canceled'
        }"
      >
        {{ doc.headline }}
      </h2>
      <p
        v-if="
          (doc.type === 'text' || doc.type === 'multimedia') &&
          doc.urgency > 2 &&
          doc.status === 'Usable'
        "
        class="line-clamp-3 text-lg leading-5 group-visited:text-gray-400"
      >
        {{ doc.paragraphs[0] }}
      </p>
    </div>
  </RouterLink>
</template>
