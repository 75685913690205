import type { Column } from '@/types'
import Dexie, { type EntityTable } from 'dexie'
import type { Document } from '@/store/parser'

const db = new Dexie('db') as Dexie & {
  columns: EntityTable<Column, 'id'>
  documents: EntityTable<Document, 'id'>
}

db.version(1).stores({
  columns: 'id,order',
  documents: 'id,shortId'
})

export async function isDbReady() {
  return new Promise(resolve => {
    db.on('ready', resolve)
  })
}

export default db
