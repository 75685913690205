import apicore, { logout as logoutFromApi } from '@/plugins/api'
import {
  addDocuments,
  appendDocumentsIdsToCol,
  prependDocumentsIdsToCol,
  resetColumn,
  updateColumnLastUpdated
} from '@/store/mutations'
import {
  getDocumentById,
  getDocumentsIdsByColumnId,
  getRefreshParamsByMode
} from './getters'
// import { useToast } from 'vue-toastification'
import { supabase } from '@/plugins/supabase'
import type { SearchQueryParams } from 'afpnews-api'
import { urlB64ToUint8Array } from '@/utilities'
import router from '@/router'

// const toast = useToast()

function apiError(error?: unknown) {
  console.error(error)
  if (
    error &&
    typeof error === 'object' &&
    'code' in error &&
    error.code === 401
  ) {
    logoutFromApi()
    router.push({ name: 'login' })
  }
  // toast.error(
  //   `Oops.. ${error && typeof error === 'object' && 'message' in error && typeof error.message == 'string' ? error.message : 'Something went wrong with the API..'}`
  // )
  return Promise.reject(error)
}

export async function searchDocuments(params: SearchQueryParams) {
  try {
    const { documents } = await apicore.search(params)

    if (!documents) return []

    return addDocuments(documents)
  } catch (error) {
    return apiError(error)
  }
}

export async function refreshColumn(
  columnId: string,
  mode: 'older' | 'newer' | 'reset'
) {
  try {
    // If the column is empty, we need to reset it
    if (getDocumentsIdsByColumnId(columnId).length === 0) {
      mode = 'reset'
    }

    // Reset the column if needed
    if (mode === 'reset') {
      await resetColumn(columnId)
    }

    // Get the refresh params
    const params = getRefreshParamsByMode(columnId, mode)

    // Fetch the documents in batches
    const batchSize = params.size || 10
    let i = 0
    const bufferDocs: unknown[] = []
    for await (const doc of apicore.searchAll(params)) {
      if (i++ > batchSize) break
      bufferDocs.push(doc)
    }

    // Parse and add documents to db
    const docs = await addDocuments(bufferDocs)

    // Add documents ids to the column
    const documentsIds = docs.map(doc => doc.id)
    switch (mode) {
      case 'reset': // If column should be resetted
      case 'older': // If documents are older than the last document in the column
        appendDocumentsIdsToCol(columnId, documentsIds)
        break
      case 'newer': // If documents are newer than the last document in the column
        prependDocumentsIdsToCol(columnId, documentsIds)
        break
      default:
    }

    // If there are less documents than the batch size, return false to avoid fetching more documents
    if (documentsIds.length < batchSize) return false

    // Return true to authorize fetching more documents
    return true
  } finally {
    // Update the column last updated date
    await updateColumnLastUpdated(columnId)
  }
}

export async function loadDocumentFromApi(docId: string) {
  console.debug(`Loading document ${docId} from API`)
  try {
    const doc = await apicore.get(docId)
    const docs = await addDocuments([doc])
    if (!docs[0]) throw new Error('Error while loading document')
    return docs[0]
  } catch (error) {
    return apiError(error)
  }
}

export async function loadDocument(docId: string) {
  try {
    const existingDoc = getDocumentById(docId)
    return existingDoc
  } catch (_) {
    return loadDocumentFromApi(docId)
  }
}

export async function loadDocFromShortId(shortId: string) {
  console.debug(`Loading document ${shortId} from API`)
  try {
    const {
      documents: [doc]
    } = await apicore.search({
      afpshortid: shortId,
      size: 1
    })
    if (!doc) throw new Error('Document not found')
    const docs = await addDocuments([doc])
    if (!docs[0]) throw new Error('Error while loading document')
    return docs[0]
  } catch (error) {
    return apiError(error)
  }
}

export async function getPushSubscription() {
  if ('serviceWorker' in navigator === false)
    throw new Error('Service worker not supported')
  const registration = await navigator.serviceWorker.getRegistration()
  if (!registration) throw new Error('Service worker not registered')
  const pushSubscription = await registration.pushManager.getSubscription()
  if (pushSubscription) return pushSubscription
  return registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlB64ToUint8Array(
      import.meta.env.VITE_VAPID_PUBLIC_KEY
    )
  })
}

export async function registerNotificationSubscription(
  columnId: string,
  params: SearchQueryParams
) {
  const pushSubscription = await getPushSubscription()
  await initWebPushService()
  await subscribeToColumn(columnId, params)
  const { error } = await supabase.from('subscriptions').insert([
    {
      column_id: columnId,
      endpoint: pushSubscription.endpoint,
      keys: pushSubscription.toJSON() as any
    }
  ])
  if (error) throw error
}

export function unregisterNotificationSubscription(columnId: string) {
  return Promise.allSettled([
    supabase.from('subscriptions').delete().eq('column_id', columnId),
    apicore.notificationCenter.deleteSubscription('web-push', columnId)
  ])
}

export async function initWebPushService() {
  const services = await apicore.notificationCenter.listServices()
  if (services.some(service => service.serviceName === 'web-push')) return
  return registerWebPushService()
}

export function registerWebPushService() {
  return apicore.notificationCenter.registerService({
    name: 'web-push',
    type: 'rest',
    datas: {
      user: '',
      password: '',
      href: `${import.meta.env.VITE_SUPABASE_URL}/functions/v1/notify`
    }
  })
}

export async function subscribeToColumn(
  columnId: string,
  params: SearchQueryParams
) {
  const activeSuscriptions =
    await apicore.notificationCenter.subscriptionsInService('web-push')
  if (activeSuscriptions.some(sub => sub.name === columnId)) return
  return apicore.notificationCenter.addSubscription(
    columnId,
    'web-push',
    params
  )
}
