<script lang="ts" setup>
  import { type Topics } from '@/types'
  import { toRefs, type PropType } from 'vue'
  import { Icon } from '@iconify/vue'

  const model = defineModel({ required: true, type: String })

  const props = defineProps({
    topicsList: {
      type: Array as PropType<Topics[any]>,
      required: true
    }
  })

  const { topicsList } = toRefs(props)

  function getTopicsIcon(topicId: string | undefined) {
    if (!topicId) return 'game-icons:newspaper'
    return (
      topicsList.value.find(topic => topic.value === topicId)?.icon ||
      'game-icons:newspaper.value'
    )
  }
</script>

<template>
  <div class="relative grow">
    <select
      v-model="model"
      name="topic"
      class="select select-lg border-0 pl-10 font-semibold focus:outline-0"
      aria-label="Topic selection"
    >
      <option
        v-for="{ label, value } in topicsList"
        :key="value"
        :value="value"
      >
        {{ label }}
      </option>
    </select>
    <div class="absolute top-1/2 left-4 transform-[translateY(-50%)]">
      <Icon :icon="getTopicsIcon(model)" />
    </div>
  </div>
</template>
