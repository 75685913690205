<script setup lang="ts">
  import TopicsConfig from '@/config/topics.json'
  import SearchInput from '@/components/SearchInput.vue'
  import SelectTopic from './SelectTopic.vue'
  import { ref, computed, toRefs } from 'vue'
  import { useDeckStore } from '@/store'
  import { useI18n } from 'vue-i18n'
  import { type SearchQueryParams } from 'afpnews-api'

  import { getColumnById } from '@/store/getters'
  import type { Topics } from '@/types'

  const deckStore = useDeckStore()
  const { updateColumnParams, hasSubscriptions, excludeAgenda } = deckStore
  const { t } = useI18n()

  const props = defineProps({
    columnId: {
      type: String,
      required: true
    }
  })
  const { columnId } = toRefs(props)

  const emit = defineEmits<{
    (e: 'reset'): void
    (e: 'close'): void
    (e: 'web-push', value: boolean): void
    (e: 'move', dir: 'left' | 'right'): void
    (e: 'toggle-agenda'): void
  }>()

  const paramsOpen = ref(false)

  const column = computed(() => getColumnById(columnId.value))
  const params = computed(() => column.value.params)
  const isSubscribed = computed(() => hasSubscriptions(columnId.value))

  function generateLabel(type: string, name: string) {
    return {
      label: t(`${type}.${name}`),
      value: name
    }
  }

  const classe = computed(() => ({
    selected: params.value.class || 'all',
    available: [
      'all',
      'text',
      'multimedia',
      'picture',
      'video',
      'graphic',
      'videography',
      'webstory',
      'factcheck'
    ].map(name => generateLabel('classes', name))
  }))

  const lang = computed(() => ({
    display: classe.value.selected !== 'picture',
    selected:
      Array.isArray(params.value.langs) && params.value.langs.length > 0
        ? params.value.langs[0]
        : 'all',
    available: ['all', 'en', 'fr', 'de', 'es', 'pt', 'ar'].map(name =>
      generateLabel('languages', name)
    )
  }))

  async function updateParams(newParams: SearchQueryParams) {
    if (newParams.class === 'picture') newParams['langs'] = []
    await updateColumnParams(columnId.value, {
      ...params.value,
      ...newParams
    })
    emit('reset')
  }

  function onQueryChange(query: string) {
    updateParams({ query })
  }

  function toggleFilters() {
    paramsOpen.value = paramsOpen.value ? false : true
  }
</script>

<template>
  <form @submit.prevent="">
    <div class="join mb-2 w-full pt-1">
      <SelectTopic
        v-if="column.type === 'topic' && typeof column.params.topic == 'string'"
        :modelValue="column.params.topic"
        @update:modelValue="($event: string) => updateParams({ topic: $event })"
        :topics-list="(TopicsConfig as Topics)[lang.selected]"
        class="join-item"
      />
      <SearchInput
        v-else-if="column.type === 'search'"
        :initial-query="params.query"
        :type="
          params.class && typeof params.class == 'string'
            ? params.class
            : 'text'
        "
        class="input input-lg join-item border-0 font-semibold focus:outline-0"
        @submit="onQueryChange"
      />

      <button
        class="btn btn-lg bg-base-100 join-item border-0 text-center outline-0"
        @click="toggleFilters"
      >
        <!-- Filters icon -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
          />
        </svg>
      </button>
    </div>

    <TransitionGroup
      name="curtain"
      tabindex="-1"
      tag="ul"
      class="list"
    >
      <li
        v-if="paramsOpen && column.type !== 'topic'"
        class="list-row p-2"
        key="classes"
      >
        <div class="list-col-grow">
          <select
            key="classe"
            :value="classe.selected"
            @change="
              ev =>
                (ev.target as HTMLSelectElement).value !== 'all'
                  ? updateParams({
                      class: (ev.target as HTMLSelectElement).value
                    })
                  : updateParams({ class: undefined })
            "
            name="classe"
            aria-label="Select a product"
            class="select"
          >
            <option
              v-for="{ label, value } in classe.available"
              :key="value"
              :value="value"
            >
              {{ label }}
            </option>
          </select>
        </div>
      </li>

      <li
        v-if="paramsOpen && lang.display"
        key="languages"
        class="list-row p-2"
      >
        <div class="list-col-grow">
          <select
            :value="lang.selected"
            @change="
              ev =>
                (ev.target as HTMLSelectElement).value !== 'all'
                  ? updateParams({
                      langs: [(ev.target as HTMLSelectElement).value]
                    })
                  : updateParams({ langs: undefined })
            "
            name="languages"
            aria-label="Select a language"
            class="select"
          >
            <option
              v-for="{ label, value } in lang.available"
              :key="value"
              :value="value"
            >
              {{ label }}
            </option>
          </select>
        </div>
      </li>

      <li
        v-if="paramsOpen && column.type == 'search'"
        key="providers"
        class="list-row p-2"
      >
        <div class="list-col-grow">
          <select
            :value="
              Array.isArray(params.provider)
                ? 'afp-only'
                : params.provider &&
                    typeof params.provider == 'object' &&
                    'exclude' in params.provider &&
                    params.provider.exclude
                  ? 'others-providers'
                  : null
            "
            @change="
              event => {
                if (
                  !event.target ||
                  'value' in event.target === false ||
                  !event.target.value
                )
                  return
                if (event.target.value === 'afp-only') {
                  updateParams({ provider: ['afp', 'أ ف ب'] })
                } else if (event.target.value == 'others-providers') {
                  updateParams({ provider: { exclude: ['afp', 'أ ف ب'] } })
                }
              }
            "
            class="select"
          >
            <option value="afp-only">
              {{ t('providers.afp-only') }}
            </option>
            <option value="others-providers">
              {{ t('providers.others-providers') }}
            </option>
          </select>
        </div>
      </li>

      <li
        v-if="paramsOpen"
        key="other-settings"
        class="list-row p-2"
      >
        <div class="list-col-grow join ml-auto">
          <button
            name="agenda"
            class="btn"
            :class="{
              'btn-success': !excludeAgenda(columnId)
            }"
            aria-label="Toggle agenda"
            @click="$emit('toggle-agenda')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
              />
            </svg>
          </button>

          <button
            name="web-push"
            class="btn"
            :class="{
              'btn-success': isSubscribed
            }"
            aria-label="Subcribe to web push notifications"
            @click="$emit('web-push', !isSubscribed)"
          >
            <svg
              v-if="isSubscribed"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.143 17.082a24.248 24.248 0 0 0 3.844.148m-3.844-.148a23.856 23.856 0 0 1-5.455-1.31 8.964 8.964 0 0 0 2.3-5.542m3.155 6.852a3 3 0 0 0 5.667 1.97m1.965-2.277L21 21m-4.225-4.225a23.81 23.81 0 0 0 3.536-1.003A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6.53 6.53m10.245 10.245L6.53 6.53M3 3l3.53 3.53"
              />
            </svg>

            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5"
              />
            </svg>
          </button>

          <button
            name="move-left"
            aria-label="Move column to left"
            class="btn"
            @click="$emit('move', 'left')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
              />
            </svg>
          </button>

          <button
            name="move-right"
            class="btn"
            aria-label="Move column to right"
            @click="$emit('move', 'right')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
              />
            </svg>
          </button>
          <button
            name="close"
            class="btn"
            aria-label="Delete column"
            @click="$emit('close')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          </button>
        </div>
      </li>
    </TransitionGroup>
  </form>
</template>

<style lang="css" scoped>
  .curtain-enter-from {
    opacity: 0;
    transform: translateY(-30px);
  }
  .curtain-enter-active {
    transition:
      opacity 0.5s,
      transform 0.5s;
  }
  .curtain-enter-to {
    opacity: 1;
    transform: translateY(0px);
  }
</style>
