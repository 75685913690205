<script setup lang="ts">
  import SlugsComponent from '@/components/SlugsComponent.vue'
  import WebShare from '@/components/WebShare.vue'
  import CopyButton from '@/components/CopyButton.vue'
  import RelatedDocuments from '@/components/RelatedDocuments.vue'
  import {
    computed,
    onBeforeMount,
    ref,
    toRefs,
    watch,
    type PropType
  } from 'vue'
  import apicore from '@/plugins/api'
  import type WebStoryDocument from '@/store/parser/WebStoryDocument'
  import DocumentDetails from '@/components/DocumentDetails.vue'
  import DockComponent from '@/components/DockComponent.vue'
  import { useRoute } from 'vue-router'

  const route = useRoute()

  const i18n = useI18n({ useScope: 'global' })
  const { t } = i18n

  const props = defineProps({
    doc: {
      type: Object as PropType<WebStoryDocument>,
      required: true
    }
  })

  const { doc } = toRefs(props)

  const emit = defineEmits<{
    (e: 'close'): void
  }>()

  const webStorySrcDoc = ref()

  async function loadWebStory() {
    webStorySrcDoc.value = await apicore.getStoryHtml({
      class: 'webstory',
      href: props.doc.href
    })
  }

  import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
  import { useI18n } from 'vue-i18n'
  const { smaller } = useBreakpoints(breakpointsTailwind)
  const isSmallerMd = smaller('sm')

  onBeforeMount(() => loadWebStory())
  watch(() => props.doc.id, loadWebStory)

  const textToCopy = computed(
    () => `${doc.value.slugs?.join(' | ')}
${doc.value.published.toLocaleDateString()} - ${doc.value.published.toLocaleTimeString()}
${doc.value.source || ''}${doc.value.source && doc.value.city ? ' • ' : ''}${doc.value.city || ''}${doc.value.city && doc.value.creator ? ' • ' : ''}${doc.value.creator || ''}

${doc.value.shortId || doc.value.id}`
  )
</script>

<template>
  <article class="bg-neutral print:flex print:bg-none">
    <DockComponent
      @close="emit('close')"
      class="bg-neutral text-base-100 dark:text-neutral-content print:hidden"
    >
      <template
        v-if="isSmallerMd"
        #left
        ><CopyButton
          :title="t('document.copy')"
          :text="textToCopy"
      /></template>
      <template
        v-if="isSmallerMd"
        #center
        ><WebShare
          :headline="doc.headline"
          :title="t('document.share')"
      /></template>
    </DockComponent>
    <figure
      ref="webstory"
      class="mx-auto aspect-[9/16] h-svh max-w-1/3 print:mr-auto print:ml-0 print:h-auto"
    >
      <iframe
        id="webstoryFrame"
        :title="props.doc.headline"
        :srcdoc="webStorySrcDoc"
        class="h-full w-full"
      />
    </figure>
    <main class="p-5 pb-20">
      <h1
        class="mb-5 text-3xl leading-7 font-bold tracking-tight sm:text-5xl sm:leading-11"
      >
        {{ doc.headline }}
      </h1>
      <DocumentDetails
        :doc="doc"
        class="mb-1"
      />
      <SlugsComponent
        :slugs="doc.slugs"
        layout="horizontal"
      />
      <div
        v-if="doc.advisory"
        class="message advisory select-none"
      >
        <p class="message-body p-4">{{ doc.advisory }}</p>
      </div>
      <div class="join hidden md:block print:hidden">
        <CopyButton
          :title="t('document.copy')"
          :text="textToCopy"
          class="btn btn-primary"
        >
          {{ t('document.copy') }}
        </CopyButton>
        <WebShare
          :headline="doc.headline"
          class="btn btn-primary"
        >
          {{ t('document.share') }}
        </WebShare>
      </div>
      <p
        v-if="route.params.docId"
        class="hidden print:block"
      >
        ID : {{ route.params.docId }}
      </p>
      <RelatedDocuments :doc="doc" />
    </main>
  </article>
</template>
