import type { Document } from '@/store/parser'
import { documents, columns, subscriptions } from '@/store/state'
import type { SearchQueryParams } from 'afpnews-api'
import { loadDocumentFromApi } from './actions'

export function getShortIdFromUno(uno: string) {
  const docId = uno.match(/-([a-z0-9]{7})/i)
  if (!Array.isArray(docId) || docId.length !== 2) throw 'Invalid document id'
  return docId[1].toUpperCase()
}

export const getDocumentById = (docId: string) => {
  const document = documents.value.find(d => d.id == docId)
  if (!document) throw new Error('Document not found')
  return document
}

export const documentExistsById = (docId: string) => {
  return documents.value.find(d => d.id == docId) !== undefined
}

export const getColumnByIndex = (index: number) => {
  const column = columns.value[index]
  if (!column) throw new Error('Column not found')
  return column
}

export const getColumnById = (id: string) => {
  const column = columns.value.find(c => c.id === id)
  if (!column) throw new Error('Column not found')
  return column
}

export const getDocumentsIdsByColumnId = (columnId: string) => {
  return getColumnById(columnId).documentsIds
}

export const getDocumentsByColumnId = (columnId: string) => {
  return getDocumentsIdsByColumnId(columnId).map((docId: string) =>
    getDocumentById(docId)
  )
}

export const getPreviousDocumentInColById = (
  columnId: string,
  docId: string
) => {
  const currentDocumentsinColumn = getDocumentsByColumnId(columnId)
  const currentDocIndexInColumn = currentDocumentsinColumn.findIndex(
    doc => doc.id === docId
  )
  const previousDocument = currentDocumentsinColumn[currentDocIndexInColumn + 1]
  return previousDocument || false
}

export const getNextDocumentInColById = (columnId: string, docId: string) => {
  const currentDocumentsinColumn = getDocumentsByColumnId(columnId)
  const currentDocIndexInColumn = currentDocumentsinColumn.findIndex(
    doc => doc.id === docId
  )
  const nextDocument = currentDocumentsinColumn[currentDocIndexInColumn - 1]
  return nextDocument || false
}

export const getFirstDocumentInCol = (columnId: string) => {
  const firstDocumentId = getDocumentsIdsByColumnId(columnId)[0]
  return getDocumentById(firstDocumentId)
}

export const getLastDocumentInCol = (columnId: string) => {
  const documentsIds = getDocumentsIdsByColumnId(columnId)
  const lastDocumentId = documentsIds[documentsIds.length - 1]
  if (!lastDocumentId) throw new Error('Last document not found')
  return getDocumentById(lastDocumentId)
}

export const agendaGenreIds = () => {
  return [
    'afpgenre:Agenda',
    'afpattribute:Agenda',
    'afpattribute:Program',
    'afpattribute:TextProgram',
    'afpattribute:AdvisoryUpdate',
    'afpattribute:Advice',
    'afpattribute:SpecialAnnouncement',
    'afpattribute:PictureProgram'
  ]
}

export const excludeGenreIds = (columnId: string) => {
  const params = getColumnById(columnId).params
  return params.genreid &&
    typeof params.genreid == 'object' &&
    'exclude' in params.genreid &&
    Array.isArray(params.genreid.exclude)
    ? params.genreid.exclude
    : []
}

export const excludeAgenda = (columnId: string) => {
  return agendaGenreIds().every(d =>
    excludeGenreIds(columnId).find(e => e === d)
  )
}

export const getRefreshParamsByMode = (
  columnId: string,
  mode: 'newer' | 'older' | 'reset'
): SearchQueryParams => {
  const params = getColumnById(columnId).params
  let lastDocument
  let firstDocument
  switch (mode) {
    case 'reset':
      break
    case 'older':
      lastDocument = getLastDocumentInCol(columnId)
      if (lastDocument) {
        const lastDate = new Date(lastDocument.published)
        lastDate.setSeconds(lastDate.getSeconds() - 1)
        params.dateTo = lastDate.toISOString()
      }
      break
    case 'newer':
      firstDocument = getFirstDocumentInCol(columnId)
      if (firstDocument) {
        const firstDate = new Date(firstDocument.published)
        firstDate.setSeconds(firstDate.getSeconds() + 1)
        params.dateFrom = firstDate.toISOString()
      }
      break
    default:
      throw new Error('Invalid mode')
  }
  return params
}

export const hasSubscriptions = (columnId: string) => {
  return subscriptions.value.has(columnId)
}
