import { TextGenericDocument } from './TextDocument'
import { VideoSchema } from './MediasShemas'
import { z } from 'zod'

const SourceSchema = z.object({
  bagItem: VideoSchema.array().optional().default([]),
  topic: z.string().array()
})

export function extractMedia(media: z.infer<typeof VideoSchema>) {
  return {
    id: media.uno,
    creator: media.creator,
    provider: media.provider?.name,
    caption: media.caption,
    dateline: media.newslines.dateline,
    sizes: media.medias.sort((a, b) => a.width - b.width)
  }
}

export default class MultimediaDocument extends TextGenericDocument {
  public type: 'multimedia'
  public medias
  public topic

  constructor(docSource: unknown) {
    super(docSource)
    this.type = 'multimedia'
    const doc = SourceSchema.parse(docSource)
    this.medias = doc.bagItem.map(extractMedia)
    this.topic = doc.topic
  }
}
