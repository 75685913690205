<script setup lang="ts">
  import SearchParams from '@/components/SearchParams.vue'
  import RecyclistNative from '@/components/RecyclistNative.vue'
  import CardComponent from '@/components/CardComponent.vue'
  import { ref, computed, toRefs } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useNetwork, useThrottleFn } from '@vueuse/core'

  const { t } = useI18n({ useScope: 'global' })

  const props = defineProps({
    columnId: {
      type: String,
      required: true
    }
  })

  const { columnId } = toRefs(props)

  import { useDeckStore } from '@/store'
  import { uniqueBy } from '@/utilities'
  import { getColumnById } from '@/store/getters'
  import {
    registerNotificationSubscription,
    unregisterNotificationSubscription
  } from '@/store/actions'
  import type { Document } from '@/store/parser'
  import { cleanColumns } from '@/store/mutations'

  const deckStore = useDeckStore()
  const { isOnline } = useNetwork()
  const {
    getDocumentsByColumnId,
    resetColumn,
    moveColumn,
    closeColumn,
    refreshColumn,
    toggleAgenda
  } = deckStore

  type Direction = 'left' | 'right'

  const noMore = ref(false)
  const isLoading = ref(false)
  const error = ref(false)

  const documents = computed(() => {
    let docs: Document[] = []
    try {
      docs = getDocumentsByColumnId(columnId.value)
    } catch (e) {
      console.error(e)
    } finally {
      return uniqueBy(docs, doc => doc.id)
    }
  })

  function reset() {
    noMore.value = false
    resetColumn(columnId.value)
    cleanColumns()
  }

  async function loadOlder() {
    if (!isOnline.value) return false
    isLoading.value = true
    error.value = false
    try {
      noMore.value = !(await refreshColumn(columnId.value, 'older'))
    } catch (e) {
      console.error(e)
      error.value = true
    } finally {
      isLoading.value = false
    }
  }

  const loadOlderThrottle = useThrottleFn(loadOlder, 100)

  async function loadNewer() {
    if (!isOnline.value) return false
    isLoading.value = true
    error.value = false
    try {
      await refreshColumn(columnId.value, 'newer')
    } catch (e) {
      console.error(e)
      error.value = true
    } finally {
      isLoading.value = false
    }
  }

  const loadNewerThrottle = useThrottleFn(loadNewer, 10000)

  function move(dir: Direction) {
    moveColumn(columnId.value, dir)
  }

  function close() {
    closeColumn(columnId.value)
    cleanColumns()
  }

  async function toggleWebPush(switchOn: boolean) {
    const column = getColumnById(columnId.value)
    if (switchOn) {
      await registerNotificationSubscription(columnId.value, column.params)
    } else {
      await unregisterNotificationSubscription(columnId.value)
    }
  }
</script>

<template>
  <section class="deck-column flex w-(--deck-column-width) flex-none flex-col">
    <SearchParams
      :column-id="columnId"
      @reset="reset"
      @close="close"
      @web-push="toggleWebPush"
      @move="(dir: Direction) => move(dir)"
      @toggle-agenda="toggleAgenda(columnId)"
      class="px-3"
    />
    <RecyclistNative
      ref="recyclist"
      :list="documents.map(d => d.id)"
      :size="10"
      :offset="200"
      :no-more="noMore"
      :error="error"
      :is-loading="isLoading"
      class="grow pr-3 pl-3"
      @load-top="loadNewerThrottle"
      @load-bottom="loadOlderThrottle"
    >
      <template #tombstone>
        <div class="flex w-full flex-col gap-4">
          <div class="skeleton h-32 w-full"></div>
          <div class="skeleton h-4 w-28"></div>
          <div class="skeleton h-4 w-full"></div>
          <div class="skeleton h-4 w-full"></div>
        </div>
      </template>
      <template #item="{ data }">
        <CardComponent
          :doc-id="data"
          :column-id="columnId"
        />
      </template>
      <template #loading>
        {{ t('column.loading') }}
      </template>
      <template #nomore>
        {{ t('column.no-result') }}
      </template>
      <template #error>
        {{ t('column.error') }}
      </template>
    </RecyclistNative>
  </section>
</template>

<style scoped>
  section {
    content-visibility: auto;
  }

  @keyframes move {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 28px 0;
    }
  }
</style>
