<script lang="ts" setup>
  import { toRefs } from 'vue'
  import { useI18n } from 'vue-i18n'

  const props = defineProps({
    hide: {
      type: Boolean,
      required: false,
      default: false
    }
  })

  const { hide } = toRefs(props)

  const i18n = useI18n({ useScope: 'global' })
  const { t } = i18n

  const emit = defineEmits<{
    (e: 'close'): void
  }>()
</script>

<template>
  <div
    :class="{
      'transform-[translateY(100%)]': hide,
      'transform-[translateY(0)]': !hide
    }"
    class="dock bottom-[0dvh] transition-transform"
  >
    <slot name="left"></slot>
    <slot name="center"></slot>
    <slot name="right">
      <button
        aria-label="Close the document"
        :title="t('document.quit')"
        @click="emit('close')"
        class="sm:w-full sm:max-w-none"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </button>
    </slot>
  </div>
</template>
