import type { Router } from 'vue-router'

export default function useHistoryState(router: Router) {
  router.afterEach(to => {
    to.meta.columnId = history.state.columnId
    if (history.state.docType) {
      to.meta.transition = [
        'text',
        'multimedia',
        'factcheck',
        'webstory'
      ].includes(history.state.docType)
        ? `slide-${history.state.direction || 'left'}`
        : 'fade'
    }
  })
}
