<script setup lang="ts">
  import SlugsComponent from '@/components/SlugsComponent.vue'
  import WebShare from '@/components/WebShare.vue'
  import DocumentDetails from '@/components/DocumentDetails.vue'
  import RelatedDocuments from '@/components/RelatedDocuments.vue'
  import { type PropType, computed, ref, toRefs, type Ref, watch } from 'vue'
  import type VideoDocument from '@/store/parser/VideoDocument'
  import DockComponent from '@/components/DockComponent.vue'
  import CopyButton from '@/components/CopyButton.vue'
  import { useElementSize, useCurrentElement, useScroll } from '@vueuse/core'
  import { useI18n } from 'vue-i18n'
  import { useRoute } from 'vue-router'

  const props = defineProps({
    doc: {
      type: Object as PropType<VideoDocument>,
      required: true
    }
  })

  const route = useRoute()

  const emit = defineEmits<{
    (e: 'close'): void
  }>()

  const i18n = useI18n({ useScope: 'global' })
  const { t } = i18n

  const { doc } = toRefs(props)

  const muted = ref(false)
  const el = useCurrentElement() as Ref<HTMLElement>
  const { width: currentWidth } = useElementSize(el)

  import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
  const { smaller } = useBreakpoints(breakpointsTailwind)
  const isSmallerMd = smaller('sm')

  const { directions } = useScroll(el)
  const showNavbar = ref(true)
  watch(directions, dir => {
    if (dir.bottom) showNavbar.value = false
    if (dir.top) showNavbar.value = true
  })

  const videoMedia = computed(() => doc.value.media)
  const videoSizes = computed(() => videoMedia.value.videoSizes)
  const pictureSizes = computed(() => videoMedia.value.pictureSizes)
  const video = computed(() =>
    videoSizes.value.findLast(d => d.width >= Math.min(currentWidth.value, 800))
  )
  const highDef = computed(() =>
    pictureSizes.value.find(size => size.role === 'Preview')
  )

  function volumeChanged(e: Event) {
    muted.value = (e.target as HTMLVideoElement).muted
  }

  const textToCopy = computed(
    () => `${doc.value.headline}

${doc.value.published.toLocaleDateString()} - ${doc.value.published.toLocaleTimeString()}
${doc.value.source || ''}${doc.value.source && doc.value.city ? ' • ' : ''}${doc.value.city || ''}${doc.value.city && doc.value.creator ? ' • ' : ''}${doc.value.creator || ''}

${doc.value.caption}

${doc.value.id}`
  )
</script>

<template>
  <article
    class="bg-base-100 max-w-[var(--document-max-width)] overflow-y-auto overscroll-contain pr-[var(--document-padding-left)] pb-[100px] pl-[var(--document-padding-left)] shadow-md sm:pr-[var(--document-padding-right)] print:w-full print:max-w-none print:bg-none"
  >
    <DockComponent
      :hide="!showNavbar"
      class="sm:hover:bg-base-200 max-w-[var(--document-max-width)] transition-transform sm:w-full print:hidden"
      @close="emit('close')"
    >
      <template
        v-if="isSmallerMd"
        #left
        ><CopyButton
          :text="textToCopy"
          :title="t('document.copy')"
      /></template>
      <template
        v-if="isSmallerMd"
        #center
        ><WebShare
          :headline="doc.headline"
          :text="textToCopy"
          :title="t('document.share')"
      /></template>
    </DockComponent>
    <figure
      class="-mr-[var(--document-padding-left)] -ml-[var(--document-padding-left)] select-none sm:-mr-[var(--document-padding-right)] print:m-auto"
    >
      <video
        :key="videoMedia.id"
        :muted="muted"
        :poster="(highDef && highDef.href) || undefined"
        controls
        controlsList="nodownload"
        autoplay
        class="w-full"
        @volumechange="volumeChanged"
      >
        <source
          v-if="video"
          :key="video.role"
          :src="video.href"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </figure>
    <main>
      <h1
        class="my-5 text-3xl leading-7 font-bold tracking-tight sm:text-5xl sm:leading-11"
      >
        {{ doc.headline }}
      </h1>
      <DocumentDetails
        :doc="doc"
        class="mb-2"
      />
      <p
        v-if="doc.caption"
        class="my-4 text-lg"
      >
        {{ doc.caption }}
      </p>
      <div
        v-if="doc.advisory"
        role="alert"
        class="alert alert-soft mb-4"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 shrink-0 stroke-current"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          />
        </svg>
        <span>{{ doc.advisory }}</span>
      </div>
      <SlugsComponent
        :slugs="doc.slugs"
        layout="horizontal"
        class="slugs select-none"
      />
      <div class="join mt-2 hidden md:block">
        <CopyButton
          :title="t('document.copy')"
          :text="textToCopy"
          class="btn btn-primary"
        >
          {{ t('document.copy') }}
        </CopyButton>
        <WebShare
          :headline="doc.headline"
          :text="textToCopy"
          class="btn btn-primary"
        >
          {{ t('document.share') }}
        </WebShare>
      </div>
      <p
        v-if="route.params.docId"
        class="hidden print:block"
      >
        ID : {{ route.params.docId }}
      </p>
      <RelatedDocuments
        :doc="doc"
        class="select-none"
      />
    </main>
  </article>
</template>
