export default {
  'fr-FR': {
    currency: {
      style: 'currency',
      currencyDisplay: 'symbol',
      currency: 'EUR'
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    },
    percent: {
      style: 'percent',
      useGrouping: false
    }
  },
  'en-US': {
    currency: {
      style: 'currency',
      currencyDisplay: 'symbol',
      currency: 'USD'
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    },
    percent: {
      style: 'percent',
      useGrouping: false
    }
  }
}
