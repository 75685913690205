import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App)
app.provide('appName', import.meta.env.VITE_APP_TITLE)

import HandleErrors from '@/plugins/handleErrors'
app.use(HandleErrors)

import { createPinia } from 'pinia'
app.use(createPinia())

import { i18n } from '@/plugins/i18n'
app.use(i18n)

import dayjs from '@/plugins/dayjs'
app.use(dayjs)

import { createHead } from '@unhead/vue/client'
app.use(createHead())

import router from './router'
app.use(router)

import { createRouterScroller } from 'vue-router-better-scroller'
app.use(
  createRouterScroller({
    selectors: {
      window: true,
      body: true,
      '#app': async ({ to, from, type, savedPosition, element }) => {
        to.meta.scrollPosition = savedPosition
      },
      '#app > article': async ({ to, from, type, savedPosition, element }) => {
        to.meta.scrollPosition = savedPosition
      }
    }
  })
)

app.mount('#app')
