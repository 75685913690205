<script lang="ts" setup>
  import ToggleTheme from '@/components/ToggleTheme.vue'
  import SelectLocale from '@/components/SelectLocale.vue'
  import { track } from '@vercel/analytics'
  import { useI18n } from 'vue-i18n'
  const i18n = useI18n({ useScope: 'global' })
  import { logout as logoutFromApi } from '@/plugins/api'
  import { signOut as signOutFromSupabase } from '@/plugins/supabase'
  import router from '@/router'

  const { t } = i18n

  async function logoutHandler() {
    track('logout')
    logoutFromApi()
    signOutFromSupabase()
    router.push({ name: 'login' })
  }
</script>

<template>
  <ul>
    <li>
      <router-link
        key="about"
        :to="{ name: 'about' }"
        name="about"
        aria-label="about"
        class="btn btn-ghost"
      >
        {{ t('about.name') }}
      </router-link>
    </li>
    <li
      class="menu-title btn btn-ghost hover:border-transparent hover:bg-transparent"
    >
      <ToggleTheme aria-label="toggle night mode" />
    </li>
    <li class="btn btn-ghost hover:border-transparent hover:bg-transparent">
      <SelectLocale />
    </li>
    <li>
      <a
        class="btn btn-ghost"
        aria-label="Log out"
        href="#"
        @click.prevent="logoutHandler"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25"
          />
        </svg>
      </a>
    </li>
  </ul>
</template>
