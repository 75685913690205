<script setup lang="ts">
  import { useI18n } from 'vue-i18n'
  import { type PropType, toRefs } from 'vue'
  import type VideoDocument from '@/store/parser/VideoDocument'
  import type TextDocument from '@/store/parser/TextDocument'
  import type MultimediaDocument from '@/store/parser/MultimediaDocument'
  import type PictureDocument from '@/store/parser/PictureDocument'
  import { useNow } from '@vueuse/core'
  import type WebStoryDocument from '@/store/parser/WebStoryDocument'

  const { t, d, tm, locale } = useI18n({ useScope: 'global' })

  const props = defineProps({
    doc: {
      type: Object as PropType<
        | VideoDocument
        | MultimediaDocument
        | TextDocument
        | PictureDocument
        | WebStoryDocument
      >,
      required: true
    }
  })
  const { doc } = toRefs(props)

  const now = useNow({ interval: 10000 })
</script>

<template>
  <div>
    <time
      pubdate
      :datetime="d(doc.published, 'short')"
      class="block"
    >
      <span
        :key="`date-created-${locale}`"
        :title="d(doc.created, 'long')"
        >{{ t('document.published') }}
        {{ $dayjs.calendar(doc.created, now, tm('calendar')) }}</span
      >

      <span v-if="doc.created.toString() !== doc.published.toString()">
        •
      </span>

      <span
        v-if="doc.created.toString() !== doc.published.toString()"
        :key="`date-updated-${locale}`"
        :title="d(doc.published, 'long')"
        >{{ t('document.updated') }}
        {{
          $dayjs.calendarRelative(
            doc.published,
            doc.created,
            now,
            tm('calendar')
          )
        }}</span
      >
    </time>

    <cite v-if="doc.source">{{ doc.source }} </cite>

    <span v-if="doc.source && doc.country && doc.city"> • </span>

    <address
      v-if="doc.country && doc.city"
      class="inline"
    >
      <RouterLink :to="`/place/${doc.country.id}/${doc.city}`">
        {{ doc.city }} ({{ doc.country.name }})
      </RouterLink>
    </address>

    <div
      v-if="doc.creator"
      class="inline"
    >
      <span
        v-for="(creator, i) in doc.creator.split(',')"
        :key="creator"
      >
        <span v-if="i == 0"> • </span>
        <span v-else="i > 0">, </span>
        <RouterLink
          :to="`/creator/${creator.trim()}`"
          rel="author"
        >
          {{ creator.trim() }}
        </RouterLink>
      </span>
    </div>
  </div>
</template>
