import { nextTick, onBeforeMount } from 'vue'
import { createI18n, type I18nOptions } from 'vue-i18n'
import datetimeFormats from '@/locales/datetimeFormats'
import numberFormats from '@/locales/numberFormats'
import en from '@/locales/en.json'
import type { Locale } from '@/types'
import { changeDayJsLocale } from '@/plugins/dayjs'
import { usePreferredLanguages, useStorage } from '@vueuse/core'

export const SUPPORT_LOCALES: Locale[] = ['en', 'fr', 'de', 'es', 'pt']

const fallbackLocale: Locale = 'en'

const options: I18nOptions = {
  legacy: false,
  globalInjection: true,
  locale: fallbackLocale,
  fallbackLocale,
  messages: {
    en: en
  },
  datetimeFormats,
  numberFormats
}

export const i18n = createI18n<false, typeof options>(options)

export function setLocale(locale: Locale): void {
  i18n.global.locale.value = locale
  document.querySelector('html')!.setAttribute('lang', locale)
  changeDayJsLocale(locale)
}

const getResourceMessages = (r: any) => r.default || r

export async function loadLocaleMessages(locale: Locale) {
  const messages = await import(`@/locales/${locale}.json`).then(
    getResourceMessages
  )

  i18n.global.setLocaleMessage(locale, messages)
  return nextTick()
}

export const preferredLocale = useStorage(
  'preferred-locale',
  usePreferredLanguages()
    .value.map(d => d.split('-')[0] as Locale)
    .filter(d => SUPPORT_LOCALES.includes(d))[0]
)

export async function setPreferredLanguage(locale: Locale) {
  preferredLocale.value = locale
  await loadLocaleMessages(locale)
  setLocale(locale)
}

export function useBestLocale() {
  onBeforeMount(async () => {
    await loadLocaleMessages(preferredLocale.value as Locale)
    setLocale(preferredLocale.value as Locale)
  })
}
