import { z } from 'zod'

const EventSchema = z.object({
  qcode: z.string(),
  keyword: z.string()
})

const SourceSchema = z.object({
  uno: z.string(),
  afpshortid: z
    .string()
    .transform(d => d.toUpperCase())
    .optional(),
  source: z.string().optional(),
  headline: z.string().optional(),
  created: z.coerce.date(),
  published: z.coerce.date(),
  embargoed: z.coerce.date().optional(),
  lang: z.string().length(2),
  afpentity: z
    .object({
      event: EventSchema.array().optional()
    })
    .optional(),
  slug: z.string().array().optional(),
  keyword: z.string().array().optional(),
  country: z.string().optional(),
  countryname: z.string().optional(),
  city: z.string().optional(),
  revision: z.number(),
  advisory: z.string().optional(),
  disclaimer: z.string().array().optional(),
  provider: z.string(),
  creator: z.string().optional(),
  status: z.enum(['Usable', 'Canceled', 'Embargoed', 'WithHeld'])
})

export default class GenericDocument {
  public id
  public shortId
  public source
  public headline
  public created
  public published
  public embargoed
  public lang
  public events
  public slugs
  public keywords
  public country
  public city
  public revision
  public disclaimer
  public provider
  public creator
  public status
  public advisory

  constructor(docSource: unknown) {
    const doc = SourceSchema.parse(docSource)
    this.id = doc.uno
    this.shortId = doc.afpshortid
    this.source = doc.source
    this.headline = doc.headline
    this.created = doc.created
    this.published = doc.published
    this.embargoed = doc.embargoed
    this.lang = doc.lang
    this.events = doc.afpentity?.event?.map(this.extractEvent) || []
    this.slugs = doc.slug
    this.keywords = doc.keyword
    this.country = {
      id: doc.country,
      name: doc.countryname
    }
    this.city = doc.city
    this.revision = doc.revision
    this.disclaimer = doc.disclaimer
    this.provider = doc.provider
    this.creator = doc.creator
    this.status = doc.status
    this.advisory = doc.advisory
  }

  private extractEvent(event: z.infer<typeof EventSchema>) {
    return {
      id: event.qcode.split(':', 2)[1],
      name: event.keyword.split(':').slice(1).join(':').trim()
    }
  }
}
