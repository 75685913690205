<script setup lang="ts">
  import CardComponent from '@/components/CardComponent.vue'
  import { ref, onBeforeMount, toRefs, type PropType } from 'vue'
  import { useDeckStore } from '@/store'
  import type { Document } from '@/store/parser'
  import { useNetwork } from '@vueuse/core'

  const deckStore = useDeckStore()
  const { isOnline } = useNetwork()
  const { searchDocuments } = deckStore

  const props = defineProps({
    doc: {
      type: Object as PropType<Document>,
      default: () => ({}),
      required: true
    },
    size: {
      type: Number,
      default: 9,
      required: false
    }
  })
  const { doc, size } = toRefs(props)

  const documents = ref<Document[]>([])

  onBeforeMount(async () => {
    await search()
  })

  async function search() {
    try {
      if (!isOnline || doc.value.events.length === 0) return false
      documents.value = await searchDocuments({
        uno: {
          exclude: [doc.value.id]
        },
        event: `afpevent:${doc.value.events[0].id}`,
        slug: {
          exclude: ['agenda']
        },
        genre: {
          exclude: ['DocRobot']
        },
        size: size.value
      })
    } catch (error) {
      console.error(error)
    }
  }
</script>

<template>
  <aside
    v-if="doc.events && documents.length > 0"
    class="mt-8 border-t-2 py-5 print:hidden"
  >
    <h3 class="relative mb-5 text-2xl leading-6 font-semibold">
      <router-link
        :to="`/event/${doc.events[0].id}`"
        class="relative z-0"
      >
        <span
          class="after:bg-success after:absolute after:bottom-[1px] after:left-0 after:z-[-1] after:block after:h-1/5 after:w-full after:content-['']"
          >{{ doc.events[0].name }}</span
        >
      </router-link>
    </h3>
    <div class="grid grid-cols-1 gap-4 sm:grid-cols-3">
      <CardComponent
        v-for="{ id } in documents"
        :key="id"
        :doc-id="id"
        class="cell"
      />
    </div>
  </aside>
</template>
