<script setup lang="ts">
  import { ref, toRefs } from 'vue'
  import { useShare } from '@vueuse/core'

  const props = defineProps({
    headline: {
      type: String,
      required: false,
      default: 'Document AFP'
    },
    text: {
      type: String,
      required: false,
      default: ''
    }
  })

  const { headline, text } = toRefs(props)
  const url = ref(window.location.href)

  const { share, isSupported: shareSupported } = useShare()

  function shareHandler() {
    return share({
      title: headline.value,
      text: text.value,
      url: url.value
    })
  }
</script>

<template>
  <button
    v-if="shareSupported"
    aria-label="Share the document"
    @click="shareHandler"
  >
    <slot>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="size-[1.2em]"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z"
        />
      </svg>
    </slot>
  </button>
</template>
