<script setup lang="ts">
  import { select } from 'd3-selection'
  import { type ZoomTransform, zoom } from 'd3-zoom'
  import { transition } from 'd3-transition'
  import { easeLinear } from 'd3-ease'
  import {
    ref,
    computed,
    watch,
    nextTick,
    toRefs,
    type Ref,
    type PropType,
    onMounted
  } from 'vue'
  import { useElementSize, useCurrentElement } from '@vueuse/core'
  import type PictureDocument from '@/store/parser/PictureDocument'

  const props = defineProps({
    sizes: {
      type: Array as PropType<PictureDocument['media']['pictureSizes']>,
      required: true
    },
    displaySmall: {
      type: Boolean,
      default: false
    }
  })

  const { sizes: mediaSizes, displaySmall } = toRefs(props)
  const imgLow = computed(() => mediaSizes.value[0])

  defineEmits<{
    (e: 'toggleDetails'): void
  }>()

  const el = useCurrentElement() as Ref<HTMLElement>
  const parentEl = computed(() => el.value?.parentElement)
  const { width: currentWidth, height: currentHeight } =
    useElementSize(parentEl)
  const scale = ref(1)
  const image = ref<HTMLElement | null>(null)

  const ratio = computed(() => imgLow.value.width / imgLow.value.height)
  const pictureWidth = computed(() =>
    Math.min(ratio.value * currentHeight.value, currentWidth.value)
  )
  const pictureHeight = computed(() => pictureWidth.value / ratio.value)
  const srcset = computed(() =>
    mediaSizes.value.map(size => `${size.href} ${size.width}w`).join(', ')
  )
  const sizes = computed(() => `${pictureWidth.value * scale.value}px`)
  const scaleExtent = computed<[number, number]>(() => {
    if (displaySmall.value) {
      return [0.8, 0.8]
    }
    return [1, 4]
  })
  const extent = computed<[[number, number], [number, number]]>(() => {
    if (displaySmall.value) {
      return [
        [0, 0],
        [currentWidth.value, pictureHeight.value * scaleExtent.value[0]]
      ]
    }
    return [
      [0, 0],
      [currentWidth.value, currentHeight.value]
    ]
  })
  const translateExtent = computed<[[number, number], [number, number]]>(() => [
    [0, 0],
    [pictureWidth.value, pictureHeight.value]
  ])
  const zoomManager = computed(() => {
    return zoom<HTMLElement, unknown>()
      .filter(event => {
        if (
          event.type == 'touchstart' &&
          event.touches.length === 1 &&
          scale.value == 1
        )
          return false
        return true
      })
      .extent(extent.value)
      .translateExtent(translateExtent.value)
      .scaleExtent(scaleExtent.value)
      .on('zoom', event => actionZoom(event))
      .on('end', event => setScale(event))
  })

  onMounted(async () => {
    await nextTick()
    enableZoom()
    resetZoom(false)
  })

  watch(displaySmall, () => {
    enableZoom()
    resetZoom(true)
  })

  watch(currentWidth, () => {
    enableZoom()
    resetZoom(false)
  })

  function enableZoom() {
    select(el.value)
      .call(zoomManager.value)
      .on('dblclick.zoom', null)
      .on('click.zoom', null)
  }

  function actionZoom(event: { transform: ZoomTransform }) {
    const { x, y, k } = event.transform
    if (image.value) {
      select(image.value).style(
        'transform',
        `translate3d(${x}px, ${y}px, 0px) scale3d(${k}, ${k}, 1)`
      )
    }
  }

  function setScale(event: { transform: ZoomTransform }) {
    scale.value = event.transform.k
  }

  function resetZoom(transitionLevel: Boolean) {
    const t = transition().duration(350).ease(easeLinear)
    zoomManager.value.scaleTo(
      transitionLevel
        ? select((el.value as HTMLElement)!).transition(t)
        : select((el.value as HTMLElement)!),
      scaleExtent.value[0]
    )
  }
</script>

<template>
  <figure
    @click="$emit('toggleDetails')"
    class="h-svh print:h-auto"
  >
    <img
      ref="image"
      :key="imgLow.href"
      :src="imgLow.href"
      :srcset="srcset"
      :sizes="sizes"
      :width="pictureWidth"
      :height="pictureHeight"
      class="origin-top-left print:h-auto print:w-full print:transform-none!"
    />
  </figure>
</template>
