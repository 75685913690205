import GenericDocument from './GenericDocument'
import { VideoSchema, VideoSizeSchema, PictureSizeSchema } from './MediasShemas'
import { descending } from 'd3-array'
import { z } from 'zod'

const SourceSchema = z.object({
  bagItem: VideoSchema.array().nonempty(),
  caption: z.string().array().optional()
})

export function extractMedia(media: z.infer<typeof VideoSchema>) {
  return {
    id: media.uno,
    creator: media.creator,
    provider: media.provider?.name,
    dateline: media.newslines.dateline,
    videoSizes: media.medias
      .filter(d => d.type === 'Video')
      .sort((a, b) => descending(a.width, b.width)) as z.infer<
      typeof VideoSizeSchema
    >[],
    pictureSizes: media.medias
      .filter(d => d.type === 'Photo')
      .sort((a, b) => descending(a.width, b.width)) as z.infer<
      typeof PictureSizeSchema
    >[]
  }
}

export default class VideoDocument extends GenericDocument {
  public type: 'video'
  public caption: string
  public media

  constructor(docSource: unknown) {
    super(docSource)
    this.type = 'video'
    const doc = SourceSchema.parse(docSource)
    this.media = extractMedia(doc.bagItem[0])
    this.caption = doc.caption ? doc.caption[0] : ''
  }
}
