import type { App, Plugin } from 'vue'
// import { useToast } from 'vue-toastification'

const HandleErrors: Plugin = {
  install(app: App) {
    // const toast = useToast()
    app.config.errorHandler = err => {
      if (err instanceof Error) {
        console.error(err)
        // toast.error(err.message)
      }
    }

    app.config.warnHandler = message => {
      console.warn(message)
      // toast.warning(message)
    }
  }
}

export default HandleErrors
