import db, { isDbReady } from '@/plugins/database'
import { useDeckStore } from '@/store'
import type { Router } from 'vue-router'

export default function useDb(router: Router) {
  router.beforeEach(async (to, _from, next) => {
    await isDbReady()

    if (to.name == 'document') {
      const docId = Array.isArray(to.params.docId)
        ? to.params.docId[0]
        : to.params.docId

      const { loadDocument, loadDocFromShortId } = useDeckStore()

      // Si l'identifiant est un shortId, le convertir en long id
      if (/^[A-Z0-9]{5,9}$/i.test(docId)) {
        // console.log('shortid', docId,  await db.documents.where('shortId').equals(docId).toArray())
        to.params.docId = (await loadDocFromShortId(docId)).id

        return next({
          ...to,
          replace: true
        })
      } else {
        // Initialise db with document. Weird bug fix
        await db.documents.get(docId)
        to.params.docId = docId
      }

      await loadDocument(to.params.docId)
    }

    return next()
  })
}
