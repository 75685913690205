import GenericDocument from './GenericDocument'
import { z } from 'zod'

const SourceSchema = z.object({
  news: z.string().array().nonempty().default(['']),
  genre: z
    .union([
      z
        .string()
        .array()
        .nonempty()
        .transform(d => d[0]),
      z.string()
    ])
    .optional(),
  urgency: z.number(),
  hopHistory: z.optional(
    z.object({
      hop: z.array(
        z.object({
          action: z.array(
            z.object({
              uri: z.string()
            })
          )
        })
      )
    })
  )
})

export enum Urgency {
  Flash = 1,
  Alert,
  Urgent,
  Normal
}

export class TextGenericDocument extends GenericDocument {
  public paragraphs
  public genre
  public urgency: Urgency
  public hasBeenAlerted

  constructor(docSource: unknown) {
    super(docSource)
    const doc = SourceSchema.parse(docSource)
    if (!this.headline && doc.urgency < 4) {
      this.headline = doc.news[0]
      this.paragraphs = doc.news.slice(1)
    } else {
      this.paragraphs = doc.news
    }
    this.genre = doc.genre
    this.urgency = doc.urgency
    this.hasBeenAlerted =
      doc.hopHistory && this.urgency > 3
        ? doc.hopHistory.hop.some(d =>
            d.action.some(e =>
              e.uri.includes('validatedAsFlashOrAlertOrUrgent')
            )
          )
        : false
  }
}

type TextType = 'text' | 'factcheck'

export default class TextDocument extends TextGenericDocument {
  public type: TextType

  constructor(docSource: unknown, type: TextType) {
    super(docSource)
    this.type = type
  }
}
