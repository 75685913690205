import GenericDocument from './GenericDocument'
import { VideoSchema } from './MediasShemas'
import { z } from 'zod'

const SourceSchema = z.object({
  bagItem: VideoSchema.array().optional().default([]),
  topic: z.string().array()
})

export function extractMedia(media: z.infer<typeof VideoSchema>) {
  return {
    id: media.uno,
    creator: media.creator,
    provider: media.provider?.name,
    caption: media.caption,
    dateline: media.newslines.dateline,
    sizes: media.medias.sort((a, b) => a.width - b.width)
  }
}

export default class WebStoryDocument extends GenericDocument {
  public type: 'webstory'
  public medias
  public href

  constructor(docSource: any) {
    super(docSource)
    this.type = 'webstory'
    this.href = docSource.href
    const doc = SourceSchema.parse(docSource)
    this.medias = doc.bagItem.map(extractMedia)
  }
}
