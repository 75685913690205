import { useDocumentVisibility, useNetwork, useIntervalFn } from '@vueuse/core'
import { computed, watch } from 'vue'
import { useRoute } from 'vue-router'

export function useAutoRefresh(
  callback: () => void,
  refreshEveryMs: number = 10000
) {
  const visibility = useDocumentVisibility()
  const { isOnline } = useNetwork()
  const route = useRoute()
  const shouldRefresh = computed(
    () =>
      visibility.value == 'visible' && isOnline.value && route.name == 'deck'
  )

  const { pause, resume, isActive } = useIntervalFn(callback, refreshEveryMs, {
    immediateCallback: true
  })

  watch(shouldRefresh, val => {
    if (val) {
      resume()
    } else {
      pause()
    }
  })

  return {
    pause,
    resume,
    isActive
  }
}
