<script setup lang="ts">
  import topicConfig from '@/config/topics.json'
  import { useI18n } from 'vue-i18n'
  import { preferredLocale } from '@/plugins/i18n'
  import { useRouter } from 'vue-router'

  const { t } = useI18n({ useScope: 'global' })
  const router = useRouter()
  import { addColumn } from '@/store/mutations'
  import { computed, ref } from 'vue'
  import type { Topics } from '@/types'

  const search = ref('')

  function searchHandler() {
    router.replace({ name: 'deck', query: { q: search.value } })
    search.value = ''
  }

  const topics = computed(() => (topicConfig as Topics)[preferredLocale.value])

  function scrollToLastColumn() {
    const columns = document.getElementsByClassName('deck-column')
    if (columns) {
      columns[columns.length - 1].scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      })
    }
  }

  router.afterEach(async to => {
    if (to.name == 'deck' && to.query.q !== undefined) {
      const query = Array.isArray(to.query.q) ? to.query.q[0] : to.query.q
      const lang = Array.isArray(to.query.lang)
        ? to.query.lang[0]
        : to.query.lang
      if (typeof query == 'string') {
        await addColumn({
          params: {
            query,
            langs: [lang || preferredLocale.value]
          }
        })
        await router.replace({ query: { q: undefined } })
        scrollToLastColumn()
      }
    }
    if (
      to.redirectedFrom &&
      to.redirectedFrom.name &&
      ['topic', 'slug', 'place', 'genre', 'event', 'creator'].includes(
        to.redirectedFrom.name as string
      )
    ) {
      scrollToLastColumn()
    }
    return true
  })
</script>

<template>
  <div>
    <!-- Search input for desktop -->
    <label class="input hidden sm:inline-flex sm:w-80">
      <svg
        class="h-[1em] opacity-50"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <g
          stroke-linejoin="round"
          stroke-linecap="round"
          stroke-width="2.5"
          fill="none"
          stroke="currentColor"
        >
          <circle
            cx="11"
            cy="11"
            r="8"
          ></circle>
          <path d="m21 21-4.3-4.3"></path>
        </g>
      </svg>
      <input
        type="search"
        required
        :placeholder="t('add-column.search-title')"
        v-model="search"
        @keyup.enter.prevent="searchHandler"
      />
    </label>
    <!-- Search button for mobiles -->
    <button
      class="btn sm:hidden"
      @click="searchHandler"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="size-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
        />
      </svg>
    </button>
    <!-- Dropdown button for topics -->
    <button
      id="topics-button"
      class="btn"
      popovertarget="topics"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="size-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z"
        />
      </svg>
      <span class="hidden sm:inline">{{ t('add-column.topic-title') }}</span>
    </button>
    <!-- Dropdown list for topics -->
    <ul
      class="dropdown menu rounded-box bg-base-100 w-52 shadow-sm"
      popover
      id="topics"
    >
      <li v-for="topic in topics">
        <RouterLink
          :to="{
            name: 'topic',
            params: { topic: topic.value, lang: preferredLocale }
          }"
          replace
        >
          {{ topic.label }}
        </RouterLink>
      </li>
    </ul>
  </div>
</template>

<style lang="css" scoped>
  #topics-button {
    anchor-name: --topics-list;
  }
  #topics {
    position: fixed;
    position-anchor: --topics-list;
    position-area: block-end;
    position-try: flip-block;
    max-inline-size: 20em;
  }
</style>
