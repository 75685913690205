import type { Column } from '@/types'
import type { Document } from '@/store/parser'
import { ref } from 'vue'
import { from } from 'rxjs'
import { liveQuery } from 'dexie'
import { useObservable } from '@vueuse/rxjs'
import db from '@/plugins/database'

export const subscriptions = ref<Set<string>>(new Set())
export const documents = useObservable<Document[], []>(
  from(liveQuery(() => db.documents.toArray())),
  {
    initialValue: []
  }
)
export const columns = useObservable<Column[], []>(
  from(liveQuery(() => db.columns.orderBy('order').toArray())),
  {
    initialValue: []
  }
)
